<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    class="sidenav"
    #drawer
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <mat-toolbar class="custom-toolbar shrink-0">
    <a
    [href]="portalURL"
    target="_blank">
      <img
        width="70%"
        alt="Logo"
        [attr.src]="appConfigService.config.brandLogoSrc"
        [style]="appConfigService.config.brandLogoStyle" />
    </a>
    </mat-toolbar>
    <mat-nav-list class="overflow-auto grow">
      <a [routerLink]="['dashboard']">
        <mat-list-item [routerLinkActive]="['is-active']">
          <mat-icon mat-list-icon>{{ 'data_usage' }}</mat-icon>
          <span matLine>{{ 'Dashboard' }}</span>
        </mat-list-item>
      </a>
      <scale-sidebar-nav>
        <scale-sidebar-nav-collapsible label="Provide">
          <a [routerLink]="['my-assets']">
            <mat-list-item [routerLinkActive]="['is-active']">
              <mat-icon mat-list-icon>{{ 'upload' }}</mat-icon>
              <span matLine>{{ 'Assets' }}</span>
            </mat-list-item>
          </a>
          <a [routerLink]="['policies']">
            <mat-list-item [routerLinkActive]="['is-active']">
              <mat-icon mat-list-icon>{{ 'policy' }}</mat-icon>
              <span matLine>{{ 'Policies' }}</span>
            </mat-list-item>
          </a>
          <a [routerLink]="['contract-definitions']">
            <mat-list-item [routerLinkActive]="['is-active']">
              <mat-icon mat-list-icon>{{ 'rule' }}</mat-icon>
              <span matLine>{{ 'Offers' }}</span>
            </mat-list-item>
          </a>
        </scale-sidebar-nav-collapsible>
      </scale-sidebar-nav>
      <scale-sidebar-nav>
        <scale-sidebar-nav-collapsible label="Consume" expanded>
          <a [routerLink]="['catalog-browser']">
            <mat-list-item [routerLinkActive]="['is-active']">
              <mat-icon mat-list-icon>{{ 'sim_card' }}</mat-icon>
              <span matLine>{{ 'Browse catalog' }}</span>
            </mat-list-item>
          </a>
          <a [routerLink]="['contracts']">
            <mat-list-item [routerLinkActive]="['is-active']">
              <mat-icon mat-list-icon>{{ 'attachment' }}</mat-icon>
              <span matLine>{{ 'Contracts' }}</span>
            </mat-list-item>
          </a>
          <a [routerLink]="['transfer-history']">
            <mat-list-item [routerLinkActive]="['is-active']">
              <mat-icon mat-list-icon>{{ 'assignment' }}</mat-icon>
              <span matLine>{{ 'Transfers' }}</span>
            </mat-list-item>
          </a>
        </scale-sidebar-nav-collapsible>
      </scale-sidebar-nav>
    </mat-nav-list>
    <div
      *ngIf="'sovity-zammad-integration' | isActiveFeature"
      id="contact-form"
      class="align-to-bottom shrink-0">
      <strong>Do you have feedback or need help?</strong>
      <p><em>Select &nbsp;<kbd>SAML</kbd>&nbsp; login on following page</em></p>
      <a
        href="https://sovity.zammad.com/#customer_ticket_new"
        target="_blank"
        mat-raised-button
        color="primary">
        CONTACT US
      </a>
      <a
        href="https://sovity.zammad.com/#knowledge_base/1/locale/en-us"
        target="_blank"
        mat-raised-button
        color="primary">
        TUTORIAL & FAQ
      </a>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        *ngIf="isHandset$ | async"
        type="button"
        mat-icon-button
        aria-label="Toggle sidenav"
        ()="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>{{ titleService.getTitle() }}</span>
      <span class="example-spacer"></span>
      <button
        class="example-icon"
        mat-icon-button
        aria-label="Example icon-button with menu icon"></button>
      <button
        class="example-icon"
        mat-icon-button
        aria-label="Example icon-button with menu icon">
        <scale-menu-flyout>
          <scale-button slot="trigger" variant="secondary" icon-only>
            <scale-icon-service-settings
              accessibility-title="Open menu" style="fill: white;"></scale-icon-service-settings>
          </scale-button>
          <scale-menu-flyout-list>
            <scale-menu-flyout-item
              ><p style="color: black; margin: 0;" (click)="this.logout()">
                Logout
              </p></scale-menu-flyout-item
            >
          </scale-menu-flyout-list>
        </scale-menu-flyout>
      </button>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
