import {LayoutModule} from '@angular/cdk/layout';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {APP_INITIALIZER} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsModule} from '@ngxs/store';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {NgChartsModule} from 'ng2-charts';
import {environment} from 'src/environments/environment';
import {ClientState} from 'src/store/client/client-state';
import {EdcDemoModule} from '../edc-demo/edc-demo.module';
import {API_KEY, CONNECTOR_DATAMANAGEMENT_API} from '../edc-dmgmt-client';
import {ApiKeyInterceptor} from './api-key.interceptor';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LogoutDialogComponent} from './components/logout-dialog/logout-dialog.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {loadAppConfigOnStartup} from './config/app-config-initializer';
import {provideConfigProperty} from './config/app-config-injection-utils';
import {AppConfigBuilder} from './config/app-config.builder';
import {AppConfigFetcher} from './config/app-config.fetcher';
import {AppConfigService} from './config/app-config.service';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    // Angular
    BrowserAnimationsModule,
    BrowserModule,
    KeycloakAngularModule,

    // Angular Material
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatListModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatDialogModule,

    // Third Party
    NgChartsModule.forRoot(),

    // store (state management)
    NgxsModule.forRoot([ClientState], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),

    // Feature Modules
    EdcDemoModule,
    LayoutModule,

    // Routing
    AppRoutingModule,
  ],
  declarations: [AppComponent, NavigationComponent, LogoutDialogComponent],
  providers: [
    AppConfigFetcher,
    AppConfigBuilder,
    AppConfigService,

    // Load and build config on page load
    loadAppConfigOnStartup(),

    // Provide individual properties of config for better Angular Component APIs
    provideConfigProperty(CONNECTOR_DATAMANAGEMENT_API, 'dataManagementApiUrl'),
    provideConfigProperty(API_KEY, 'dataManagementApiKey'),

    {provide: HTTP_INTERCEPTORS, multi: true, useClass: ApiKeyInterceptor},

    MatDatepickerModule,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        color: 'accent',
      } as MatFormFieldDefaultOptions,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
