<div id="wrapper">
  <div class="flex flex-row justify-center">
    <edc-demo-loading-state
      *ngIf="contractList.isLoading"
      class="min-h-[35rem]"></edc-demo-loading-state>
    <edc-demo-error-state
      *ngIf="contractList.isError"
      class="min-h-[35rem]"
      [errorMessage]="contractList.error.failureMessage"></edc-demo-error-state>
    <edc-demo-empty-state
      *ngIf="contractList.isReady && !contractList.data.length"
      class="min-h-[35rem]"
      [emptyMessage]="'No contracts found.'"></edc-demo-empty-state>
  </div>
  <div
    *ngIf="contractList.isReady && !!contractList.data.length"
    class="flex flex-row flex-wrap">
    <mat-card *ngFor="let contract of contractList.data" class="contract-card">
      <mat-card-header>
        <mat-icon mat-card-avatar>attachment</mat-icon>
        <mat-card-title>{{ contract.id }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <!-- asset -->
          <mat-list-item>
            <mat-icon mat-list-icon>category</mat-icon>
            <div class="contract-property-header" mat-line>Asset</div>
            <div class="contract-property-value" mat-line>
              {{ contract.assetId }}
            </div>
          </mat-list-item>

          <!-- provider -->
          <mat-list-item>
            <mat-icon mat-list-icon>person</mat-icon>
            <div class="contract-property-header" mat-line>Provider</div>
            <div class="contract-property-value" mat-line>
              {{ contract.providerAgentId }}
            </div>
          </mat-list-item>

          <!-- sign date -->
          <mat-list-item>
            <mat-icon mat-list-icon>draw</mat-icon>
            <div class="contract-property-header" mat-line>Signing date</div>
            <div class="contract-property-value" mat-line>
              {{ asDate(contract.contractSigningDate) }}
            </div>
          </mat-list-item>

          <!-- valididty -->
          <mat-list-item>
            <mat-icon mat-list-icon>access_time</mat-icon>
            <div class="contract-property-header" mat-line>Validity</div>
            <div class="contract-property-value" mat-line>
              {{ asDate(contract.contractStartDate) }} -
              {{ asDate(contract.contractEndDate) }}
            </div>
          </mat-list-item>

          <mat-divider inset></mat-divider>
          <mat-card-actions class="card-actions">
            <button
              color="primary"
              mat-raised-button
              [disabled]="isTransferInProgress(contract.id!)"
              (click)="onTransferClicked(contract)">
              <mat-icon>downloading</mat-icon>
              Transfer
            </button>
          </mat-card-actions>
          <mat-card-footer class="contract-card-footer">
            <mat-progress-bar
              *ngIf="isTransferInProgress(contract.id!)"
              color="primary"
              mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>
