<div id="wrapper">
  <div class="flex flex-row">
    <!-- Search -->
    <mat-form-field
      class="search-form-field"
      appearance="outline"
      color="accent">
      <mat-label>Search contract definitions</mat-label>
      <input matInput [formControl]="searchText" />
      <mat-icon matPrefix>search</mat-icon>
      <button
        *ngIf="searchText.value"
        mat-icon-button
        matSuffix
        (click)="searchText.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Create Button -->
    <button
      id="create-button"
      color="primary"
      mat-raised-button
      style="margin-top: 8px; height: 44px"
      (click)="onCreate()">
      <mat-icon>add_circle_outline</mat-icon>
      Create Contract Definition
    </button>

    <!-- Spacer -->
    <div class="grow"></div>

    <!-- Paginator -->
    <mat-paginator
      *ngIf="contractDefinitionList.isReady"
      [hidePageSize]="true"
      [disabled]="true"
      [pageSize]="contractDefinitionList.data.contractDefinitionCards.length"
      [length]="
        contractDefinitionList.data.numTotalContractDefinitions
      "></mat-paginator>
  </div>

  <div class="flex flex-row justify-center">
    <edc-demo-loading-state
      *ngIf="contractDefinitionList.isLoading"
      class="min-h-[35rem]"></edc-demo-loading-state>
    <edc-demo-error-state
      *ngIf="contractDefinitionList.isError"
      class="min-h-[35rem]"
      [errorMessage]="
        contractDefinitionList.error.failureMessage
      "></edc-demo-error-state>
    <edc-demo-empty-state
      *ngIf="
        contractDefinitionList.isReady &&
        (!contractDefinitionList.data.numTotalContractDefinitions ||
          !contractDefinitionList.data.contractDefinitionCards.length)
      "
      class="min-h-[35rem]"
      [emptyMessage]="
        'No contract definition found with given filter.'
      "></edc-demo-empty-state>
  </div>
  <edc-demo-contract-definition-cards
    *ngIf="
      contractDefinitionList.isReady &&
      !!contractDefinitionList.data.contractDefinitionCards.length
    "
    [contractDefinitionCards]="
      contractDefinitionList.data.contractDefinitionCards
    "
    [deleteBusy]="deleteBusy"
    (deleteDone)="refresh()"></edc-demo-contract-definition-cards>
</div>
